@use "../global/style.scss";

.home-hero-section {
    height: 70vh;
    display: flex;
    justify-content: center;
    margin: 0 5rem;

    .hero-content {
        width: 70vw;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;

        h1 {
            @include style.header-text;
            font-size: 3rem;
            font-weight: 700;
        }

        p {
            @include style.body-text;
            font-size: 1rem;
            color: style.$body-text;
        }
    }

}

.home-cs-section {
    height: 80vh;
    background: style.$body-bg;

    .contentStyle {
        height: 80vh;
        color: #fff;
        text-align: center;
        background: style.$body-bg;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        .cs-badge {
            @include style.body-text;
            font-weight: 600;
            padding: 0.5rem 1rem;
            color: style.$primary-color;
            background: rgb(235, 94, 40, 0.1);
            border-radius: 100px;
            width: fit-content;
        }

        .cs-title {
            @include style.header-text;
            font-size: 2.5rem;
            font-weight: 700;
        }

        .cs-body {
            @include style.body-text;
            font-size: 1rem;
            color: style.$body-text;
            width: 70vw;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 550px) {

    .home-hero-section {
        margin: 0 2rem;

        .hero-content {
            width: fit-content;

            h1 {
                font-size: 2.5rem;
                line-height: 3rem;
            }
        }

    }
    .home-cs-section {
        .contentStyle {
            gap: 0.8rem;
            padding: 0 1rem;
            .cs-badge {
                font-size: 0.8rem;
            }
            .cs-title {
                font-size: 2rem;
            }
            .cs-body {
                width: fit-content;
            }
        }
    }

}