@use "../global/style.scss";

.footer-menu {
    border-top: 1px solid style.$header-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
    height: 80px;

    .cprt-text {
        @include style.body-text;
    }

.contact-link{
        text-decoration: underline;
        list-style: none;
        color: style.$body-text;
        text-transform: uppercase;
        @include style.header-text;
    }
}

@media screen and (max-width: 550px) {
    .footer-menu {
        padding: 0 2rem;
        .cprt-text {
            font-size: 0.8rem;
        }
    }    
  
}