//Colors
$white: #ffffff;
$black: #121212;
$header-bg: #252422;
$body-bg: #1B1A18;
$primary-color: #EB5E28;
$body-text: #CCC5B9;


//Fonts
$font-header: "Oswald";
$font-body: "Lato";


@mixin header-text {
    font-family: $font-header;
    color: $white;
    font-weight: 500;
    text-transform: uppercase;
}

@mixin body-text {
    font-family: $font-body;
    color: $white;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.2px;
}


body {
    background: $black;
}

// @font-face {
//     font-family: "Norwester";
//     src: url("../Assets/norwester.woff") format('woff');
//     // src: url("../Assets/norwester.otf");

// }




//CSS RESET

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}