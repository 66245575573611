@use "../global/style.scss";

.header-menu {
    position: relative;
    border-bottom: 1px solid style.$header-bg;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
    height: 80px;

    ul {
        display: flex;
        gap: 2rem;

        li a {
            list-style: none;
            color: style.$body-text;
            text-transform: uppercase;
            text-decoration: none;
            @include style.header-text;
        }
    }

    .app-logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        color: white;

    }

    .contact-link {
        text-decoration: underline;
    }
}

.active {
    color: style.$primary-color !important;
}

li a:hover {
    text-decoration: line-through !important;
}

//Mobile Responsive starts here

@media screen and (max-width: 550px) {

    .header-menu {
        padding: 0 2rem;
        ul {
            gap: 1rem;
        }
        .app-logo {
            display: none;

        }

    }
}