@use "../global/style.scss";


.products-hero-section {
    height: 50vh;
    display: flex;
    justify-content: center;
    margin: 0 5rem;

    .hero-content {
        // width: 70vw;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;

        h1 {
            @include style.header-text;
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 4rem;
        }

        p {
            @include style.body-text;
            font-size: 1rem;
            color: style.$body-text;
        }
    }
}

.products-content-section {
    background: style.$body-bg;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;

    .content-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        h1 {
            @include style.header-text;
            font-size: 1.5rem;
            font-weight: 700;
            color: style.$body-text;
        }

        .cards-container {
            display: flex;
            gap: 2rem;

            .card {
                width: 300px;
                height: 400px;
                // background: rgb(235, 94, 40, 0.1);
                background: style.$black;
                border: none;
                display: flex;
                align-items: end;

                p {
                    @include style.header-text;
                    font-size: 1.5rem;
                    text-align: left;
                }
            }
        }


    }
}