@use "../global/style.scss";

.partners-main-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8rem;
}

.partner-hero-section {
    display: flex;
    justify-content: center;
    margin: 0 5rem;

    .hero-content {
        // width: 70vw;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;

        h1 {
            @include style.header-text;
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 4rem;
        }
        p {
            @include style.body-text;
            font-size: 1rem;
            color: style.$body-text;
        }
    }
}

.partners-content-section{
    display: flex;
    justify-content: center;
    .cards-container{
        display: flex;
        gap: 1rem;
        .card{
            width: 300px;
            height: 400px;
            background: style.$body-bg;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                @include style.header-text;
                font-size: 1.2rem;
                text-align: left;
                color: style.$body-text;
            }
        }
    }
}